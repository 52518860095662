.filter-group-settings {
  .filter-description {
    display: flex;
    overflow: auto;
    padding: 3px 0;
    width: 100%;

    .toggle-container {
      text-align: right;
    }
  }

  .ant-spin-nested-loading {
    width: 100%;
  }
}
