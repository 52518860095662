@use "sass:math";

$dotSize: 10px;
.component-progress-bar {
  width: calc(100% - #{$dotSize});
  background-color: #ddd;
  opacity: 0;
  transition: opacity 0.15s linear;
  position: relative;
  z-index: 100;

  &:not(.visible) {
    transition: opacity 0.5s linear 0.5s;
  }

  &.visible {
    opacity: 1;
  }

  .progress-bar-background {
    height: 1px;
    background-color: #6ab650;
    transition: width 0.4s ease-in-out;
  }

  .progress-bar-dot {
    height: $dotSize;
    width: $dotSize;
    border-radius: 50%;
    background-color: #6ab650;
    position: absolute;
    margin-top: -5px;
    transform: translateX(math.div($dotSize, 2) * -1);
    transition: left 0.4s ease-in-out;
  }
}