.service-line-table {
  tr .ant-btn-icon-only:not(.expanded) {
    opacity: 0;
  }

  tr:hover .ant-btn-icon-only {
    opacity: 1;
  }

  .p-selection-column {
    padding: 0 !important;
    width: 0;
  }

  .p-datatable .p-radiobutton .p-radiobutton-box {
    display: none;
  }

  .p-datatable .p-datatable-tbody tr.p-row-expanded > td {
    padding: 0!important;
  }
}

.care-family-table {
  tr th.p-column-group-cell--header {
    border-bottom: none;
  }

  tr > td:first-of-type > div {
    padding-left: 1rem;
  }

  tr > td:not(:first-of-type) {
    font-weight: 100;
  }

  .p-selection-column {
    padding: 0 !important;
    width: 0;
  }

  .p-datatable .p-radiobutton .p-radiobutton-box {
    display: none;
  }

  thead tr {
    padding: 1rem;
    font-style: italic;
    span {
      margin-left: 1rem;
    }
  }

  thead tr {
    background-color: #f4f4ff !important;
  }

  tbody tr:not(:hover) {
    background-color: #f4f4ff !important;
  }

  .p-datatable-scrollable-header-box {
    margin-right: 0 !important;
  }
}