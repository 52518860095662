.bm-bar-user{
    background-color:#EF5350;
}
.bm-bar-median{
    background-color:#00a1b5;
}
.bm-bar-dot{
    background-color:#ddd;
    cursor: pointer;
}
.bm-bar-inner{
    background-color:#EFEAF7;
    border: 1px solid #7b1fa2;
}
.bm-bar-legend-container{
    font-size:11px;
}
.bm-bar-legend-container.strike {
    text-decoration: line-through;
}
.bm-bar-legend-container.pointer {
    cursor: pointer;
}
.bm-bar-legend-container--off {
    opacity: 0.5;
}
.bm-bar-legend-c{
    height:10px;
    width:10px;
    border-radius: 50px;
    margin-top:2px;
    margin-right:4px;
    float:left;
}
.bm-bar-legend{
    height:10px;
    width:15px;
    margin-top:2px;
    margin-right:4px;
    float:left;
}
.bm-bee-legend {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    margin-top: 2px;
    margin-right: 4px;
    float: left;
}

.bm-bee-user {
    background-color: #EF5350;
}

.bm-bee-outer {
    background-color: #B9B9B9;
}

.bm-bee-inner {
    background-color: #7B1FA2;
}

.bm-bee-legend-container {
    font-size: 11px;
    cursor: pointer;
}

.bm-bee-legend-container-checked {
    font-size: 11px;
    opacity: 0.5;
    cursor: pointer;
}