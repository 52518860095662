@import '~@strata/tempo/lib/styles/Base.css';
@import '~@strata/tempo/lib/styles/Components.css';

.tempo-layout .ant-layout-content {
  overflow-y: scroll;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  backdrop-filter: blur(2px);
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.ant-typography + .ant-form-item {
  margin-top: 8px;
}