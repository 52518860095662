@import '~@strata/styles/tempostyle.scss';

.cost-table {
  margin: 0 24px;
  .accordion-button {
    cursor: pointer;
    .tempo-spacing-container:hover {
      background-color: $tempo-color-gray-200;
    }
  }
}