/* Old file: KPIOrganizer.css */
.bm-kpiorg{
    background: #FAFAFA;
    padding: 4px;
    display: block;
    width:100%;
}

.bm-flex{
    flex-direction: row;
    width:100%;
}

@media only screen and (max-width: 600px) {
    .bm-kpiorg{
        width: 100%;
    }
}