.bm-control-header{
    background-color:white;
    width:100%;
    min-height:53px;
    border-bottom:1px solid rgb(206, 206, 206);
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 500;
}
.bm-header-name{
    float:left;
    margin-top: 12px;
    margin-left: 16px;
}

@media only screen and (max-width: 600px) {
    .bm-header-name{
        display: none;
    }
}