.bm-ab-small{
    text-align: center;
    display: none;
}
.bm-ab-large{

}

@media only screen and (max-width: 600px) {
    .bm-ab-small{
    text-align: center;
        display: inherit;
    }
    .bm-ab-large{
        display: none;
    }
}